import React from 'react';
import style from './BadgeContainer.module.css';
import AppleBadge from '../../images/common/badge-apple.svg';
import GoogleBadge from '../../images/common/badge-google.svg';

class BadgeContainer extends React.Component {
    render(){
        return (
            <div className="SectionContainer">
                <p className="SectionHeading">Get the App from your favourite App store:</p>
                <div className={style.BadgeContainer}>
                    <a href="https://itunes.apple.com/au/app/heart2heart-canine-rrr/id839134005?mt=8" className="AppLink" target="_blank" rel="noopener noreferrer">
                        <img src={AppleBadge} className={style.AppLinkImage} alt="App Store Badge" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="https://play.google.com/store/apps/details?id=com.boehringer.restingrespiratoryrate&hl=en" className="AppLink" target="_blank" rel="noopener noreferrer">
                        <img src={GoogleBadge} className={style.AppLinkImage} alt="Google Store Badge" />
                    </a>
                </div>
            </div>
        )
    }
}

export default BadgeContainer;

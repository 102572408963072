import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/common/Layout.jsx';
import MeasureHealthBanner from '../components/measure-health/MeasureHealthBanner.jsx';
import BadgeContainer from '../components/measure-health/BadgeContainer.jsx';
import { Helmet } from 'react-helmet';

class MeasureHealth extends React.PureComponent {
    render(){
        return (
            <Layout>
                <Helmet>
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Read now for heart information in dogs." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Measure your dog's heart health - Boehringer Ingelheim" />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content="Read now for heart information in dogs." />
                    <meta property="og:locale" content="en_AU" />

                    <title>Measure your dog's heart health - Boehringer Ingelheim 2019</title>
                </Helmet>
                <MeasureHealthBanner/>
                <div className="MeasureHealthMainContainer">
                    <div className="ContentContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionTitle">Download the free App from your favourite App store &ndash; see links below</p>
                            </div>
                            <div className="SectionContainer">
                                <p className="SectionTitle">You play an important part in your dog's health</p>
                                <p className="SectionText">Finding out that your dog has heart disease may be unexpected and worrying. The good news is there is treatment available from your vet to help delay progression of your dog’s heart disease, or treat the signs of heart failure, giving you more quality time with your dog.</p>
                                <p className="SectionTitle">Take the initiative in early diagnosis</p>
                                <p className="SectionText">Staying informed and knowing what signs to look for as the disease progresses is important to provide optimal treatment and maintain your dog’s quality of life.</p>
                                <p className="SectionText">Your dog’s resting or sleeping respiratory rate (known as RRR) may provide an important early clue as to the progression of heart disease. A dog’s RRR can indicate heart failure before outward signs appear. An increase in your dog's RRR may be an early indicator that their heart is beginning to fail or the disease is progressing. By looking for these early changes, treatments can be implemented, or changed, to better manage their condition. That’s why monitoring RRR at home can be valuable. Tracking the RRR can provide your vet with critical information about how your dog’s heart condition is progressing.<sup>1</sup></p>
                                <div className="ImageContainer">
                                    <Img
                                        fluid={this.props.data.phoneAppImg.childImageSharp.fluid}
                                    />
                                </div>
                                <p className="SectionTitle">How to measure your dog's RRR</p>
                                <p className="SectionText">Talk to your vet about the 'Heart2Heart Canine Resting Respiratory Rate Tracker App' available from the App Store or Google Play.</p>
                                <p className="SectionText">It is a free, easy-to-use App to help you track your dog’s RRR, along with instructional materials to get you started.</p>
                                <p className="SectionText">To measure the RRR simply count the number of breaths per minute and track them, either using the free RRR App or by keeping a record of them in a diary.</p>
                                <p className="SectionTitle">Assess your dog’s RRR while they are sleeping:</p>
                                <ul className="SectionList">
                                    <li className="SectionListElement">&bull; count the number of times your dog breathes in one minute (inhale + exhale = 1 breath) and record this</li>
                                    <li className="SectionListElement">&bull; the normal RRR is usually less than 30 breaths per minute</li>
                                    <li className="SectionListElement">&bull; your vet will give you the ‘normal’ RRR for your dog, so you know when a reading is too high</li>
                                    <li className="SectionListElement">&bull; if the recorded RRR is greater than your dog’s ‘normal’ RRR, contact your vet</li>
                                    <li className="SectionListElement">&bull; monitor at least once per month</li>
                                </ul>
                                <p className="SectionText">If your dog has been diagnosed with Stage B2 heart disease<sup>*</sup>, RRR should be recorded once a week. Measure on the same day each week to make this part of your regular routine.</p>
                                <p className="SectionText">&#42; For information on the Stages of Heart disease see:<br /><Link to="/heart-disease/stages-of-heart-disease">What are the stages of heart disease?</Link></p>
                            </div>
                            <div className="ReferenceContainer">
                                <p className="ReferenceText">Reference:</p>
                                <p className="ReferenceText">1. Schober KE, et al. (2010) Detection of congestive heart failure in dogs by Doppler echocardiology. <em>J Vet Intern Med.</em>;246:1358-1368.</p>
                            </div>
                            <BadgeContainer/>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default MeasureHealth;

export const query = graphql`{
    phoneAppImg: file(relativePath: { eq: "measureHealth/iphone.png" }){
        childImageSharp {
            fluid(maxWidth: 950){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`
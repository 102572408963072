import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './MeasureHealthBanner.module.css';

class MeasureHealthBannerImage extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.measureHealthBannerImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                measureHealthBannerImage: file(relativePath: { eq: "measureHealth/measure-health-banner.png" }){
                    childImageSharp {
                        fluid(maxWidth: 1440){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;
            let isIE = false;
            
            if( isIE ){
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <MeasureHealthBannerImage measureHealthBannerImage={data.measureHealthBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <div className={style.MHTitleTable}>
                                <div className={style.MHTitleTableCell}>
                                    <p className={style.HealthyHeartsTitle}>Measure your dog's heart health</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Measure your dog's heart health
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <MeasureHealthBannerImage measureHealthBannerImage={data.measureHealthBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <p className={style.HealthyHeartsTitle}>Measure your dog's heart health</p>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Measure your dog's heart health
                        </div>
                    </div>
                )
            }
        }}
    />
)
